<template>
  <div id="app">
    <div class="top-container">
      <div class="ajout-container">
        <h2 id="reference">Fournisseurs</h2>
        <div class="select-fournisseur-container">
          <select name="ref" id="fournisseur" v-model="fournisseur">
            <option>Sélectionner un fournisseur</option>
            <option v-for="elt in fournisseurs" v-bind:key="elt.key" class="elements">{{ elt.value }}</option>
          </select>
          <button id="suppFournisseur" @click="suppFournisseur()"><svg xmlns='http://www.w3.org/2000/svg' width="30px" height="30px" viewBox='0 0 512 512'><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg></button>
        </div>
        <h2 id="reference" v-show="fournisseur != 'Sélectionner un fournisseur'">Produits</h2>
        <div class="select-container" v-show="fournisseur != 'Sélectionner un fournisseur'">
          <select name="ref" id="ref">
            <option>Sélectionner un produit</option>
            <option v-for="elt in produits" v-bind:key="elt.key" class="elements">{{ elt.value }}</option>
          </select>
          <button id="suppProduit" @click="suppProduit()"><svg xmlns='http://www.w3.org/2000/svg' width="30px" height="30px" viewBox='0 0 512 512'><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg></button>
        </div>
        <button @click="ajouterACommande()" v-show="fournisseur != 'Sélectionner un fournisseur'">Ajouter au tableau</button>
      </div>
      <div class="referenceAAjouter-container">


        <h2 v-show="edit == 1">Ajouter un produit</h2>
        <div class="popup-container" id="popup-produit-container" v-show="edit == 1">{{ ref }} ajouté avec succès</div>
        <input v-show="edit == 1" class="referenceAAjouterInput" type="text" id="referenceAAjouter" placeholder="Nom du produit" />
        <select v-show="edit == 1" id="fournisseurProduit">
          <option>Sélectionner un fournisseur</option>
          <option v-for="fournisseur in fournisseurs" :key="fournisseur.key">{{ fournisseur.value }}</option>
        </select>
        <div class="buttons" v-show="edit == 1">
          <button @click="ajouterReference()">Ajouter</button>
          <button @click="edit = 0">Annuler</button>
        </div>


        <h2 v-show="edit == 2">Ajouter un fournisseur</h2>
        <div class="popup-container" id="popup-fournisseur-container" v-show="edit == 2">{{ ref }} ajouté avec succès</div>
        <input v-show="edit == 2" class="referenceAAjouterInput" type="text" id="fournisseurAAjouter" placeholder="Nom du produit" />
        <div class="buttons" v-show="edit == 2">
          <button @click="ajouterFournisseur()">Ajouter</button>
          <button @click="edit = 0">Annuler</button>
        </div>


        <div class="buttons" v-show="edit == 0">
          <button @click="edit = 2">Fournisseur</button>
          <button @click="edit = 1">Produit</button>
        </div>
      </div>
    </div>

    <div class="table-container">
      <h2 v-show="commande.length > 0">{{commande.length}} produit<span v-if="commande.length > 1">s</span></h2>
      <table v-if="commande.length > 0">
        <thead>
          <tr>
            <th id="left">Produit<span v-if="commande.length > 1">s</span></th>
            <th id="right">Fournisseur<span v-if="commande.length > 1">s</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in commande" v-bind:key="item.key">
            <td class="lignes" :class="{ dernierProduit: commande.length-1 == index }" @dblclick="supp(item)">{{ item.value }}</td>
            <td class="lignes" :class="{ dernierFournisseur: commande.length-1 == index }" @dblclick="supp(item)">{{ item.fournisseur }}</td>
          </tr>
        </tbody>
      </table>
      <button v-show="commande.length > 0" @click="toutSupp()" id="toutSupp">Tout effacer</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: 'App',
  data(){
    return{
      fournisseurs: [],
      fournisseur: "",
      refs: [],
      produits: [],
      commande: [],
      tmp: 0,
      ref: "",
      edit: 0
    }
  },
  created: function(){
    //firebase.database().ref("Fournisseurs/").push("Test");

    this.fournisseur = "Sélectionner un fournisseur";
    firebase.database().ref('Fournisseurs/').on('value', (snapshot) => {
      this.fournisseurs.splice(0,this.fournisseurs.length);
      snapshot.forEach((elt) => {
        this.fournisseurs.push({
          key: elt.key,
          value: elt.val()
        })
        this.fournisseurs.sort((a,b) => a.value < b.value ? -1 : 1);
      })
    })
    firebase.database().ref('Refs/').on('value', (snapshot) => {
      this.refs.splice(0,this.refs.length);
      snapshot.forEach((elt) => {
        if (!this.refs.includes(elt.val()))
          this.refs.push({
            key: elt.key,
            value: elt.val().value,
            fournisseur: elt.val().fournisseur
          });
      });
      this.refs.sort((a,b) => a.value < b.value ? -1 : 1);
      this.produits = this.refs.filter(r => r.fournisseur == this.fournisseur)
    });
    firebase.database().ref('Commande/').on('value', (snapshot) => {
      this.commande.splice(0,this.commande.length);
      snapshot.forEach((elt) => {
          this.commande.push({
            key: elt.key,
            value: elt.val().value,
            fournisseur: elt.val().fournisseur
          });
      });
      //this.commande.sort((a,b) => a.fournisseur < b.fournisseur && a.value < b.value ? -1 : 1);
      this.commande.sort(this.tri);
    });
  },
  methods: {
    tri(a,b){
      if (a.fournisseur < b.fournisseur) {
        return -1;
      }else if (a.fournisseur > b.fournisseur) {
        return 1;
      }else{
        if (a.value < b.value) {
          return -1;
        }else{
          return 1;
        }
      }
    },
    ajouterACommande(){
      if (document.querySelector('#ref').value != "Sélectionner un produit"){
        if (!this.estDedans(document.querySelector('#ref').value)){
          firebase.database().ref('Commande/').push({
            value: document.querySelector('#ref').value,
            fournisseur: this.fournisseur
          })
          document.querySelector('#ref').value = "Sélectionner un produit"
        }else{
          alert(document.querySelector("#ref").value+" est déjà dans le tableau")
        }
      }else{
        alert("Veuillez saisir un produit à ajouter")
      }
    },
    toutSupp(){
      if (confirm("Tout supprimer ?")) {
        firebase.database().ref('Commande/').remove();
      }
    },
    estDedans(item){
      var trouver = false;
      this.commande.forEach(elt => {
        if (elt.value == item && elt.fournisseur == this.fournisseur) {
          trouver = true;
        }
      })
      return trouver;
    },
    ajouterReference(){
      var tmp = document.querySelector('#referenceAAjouter').value;
      var tmp2 = document.querySelector('#fournisseurProduit').value;
      tmp = tmp.trim();
      tmp = tmp.toLowerCase();
      tmp = tmp.charAt(0).toUpperCase()+tmp.substr(1);
      if ( tmp == "" || tmp2 == "Sélectionner un fournisseur" ) {
        alert("Veuillez bien saisir tout les champs")
      }else{
        var trouve = false;

        this.refs.forEach(elt => {
          if (elt.value == tmp && elt.fournisseur == tmp2) {
            trouve = true
          }
        })

        if (!trouve) {
          firebase.database().ref('Refs/').push({
            value: tmp,
            fournisseur: tmp2
          });
          this.ref = tmp;
          document.querySelector('#referenceAAjouter').value = "";
          document.querySelector('#fournisseurProduit').value = "Sélectionner un fournisseur";
          this.popup(1)
          this.produits = this.refs.filter(r => r.fournisseur == this.fournisseur)
        }else{
          alert(tmp+" chez "+tmp2+" existe déjà");
        }
      }
    },
    ajouterFournisseur(){
      var tmp = document.querySelector('#fournisseurAAjouter').value;
      if(tmp != ""){
        var trouve = false;
        this.fournisseurs.forEach(fournisseur => {
          if (fournisseur.value == tmp) {
            trouve = true
          }
        })

        if (!trouve) {
          firebase.database().ref('Fournisseurs/').push(tmp)
        }
        this.ref = document.querySelector('#fournisseurAAjouter').value;

        document.querySelector('#fournisseurAAjouter').value = ""
        this.popup(2)
      }
    },
    suppProduit(){
      var value = document.querySelector('#ref').value;
      if (value != "Sélectionner un produit" && confirm("Supprimer "+value+" de "+this.fournisseur)) {
        this.refs.forEach(elt => {
          if (elt.value == value && elt.fournisseur == this.fournisseur) {
            this.commande.forEach(item => {
              if (item.value == value && item.fournisseur == this.fournisseur) {
                firebase.database().ref('Commande/'+item.key).remove();
              }
            })
            firebase.database().ref('Refs/'+elt.key).remove();
          }
        })
        document.querySelector('#ref').value = "Sélectionner un produit"
      }
    },
    suppFournisseur(){
      var tmp = []
      if (this.fournisseur != "Sélectionner un fournisseur" && confirm("Supprimer "+this.fournisseur)) {

        this.commande.forEach((produit) => {
          if (produit.fournisseur == this.fournisseur) {
            tmp.push(produit.key)
          }
        })

        tmp.forEach(elt =>{
          firebase.database().ref('Commande/'+elt).remove();
        })

        tmp = []

        this.refs.forEach((item) => {
          if (item.fournisseur == this.fournisseur) {
            tmp.push(item.key)
          }
        })

        tmp.forEach(elt =>{
          firebase.database().ref('Refs/'+elt).remove();
        })

        this.fournisseurs.forEach(elt => {
          if (elt.value == this.fournisseur) {
            firebase.database().ref('Fournisseurs/'+elt.key).remove();
          }
        })

        this.fournisseur = "Sélectionner un fournisseur"
      }
    },
    popup(select){
      if (select == 1) {
        document.querySelector("#popup-produit-container").classList.toggle("open-popup");
        window.setTimeout(() => {
          document.querySelector("#popup-produit-container").classList.toggle("open-popup")
        },1000)
      }else{
        document.querySelector("#popup-fournisseur-container").classList.toggle("open-popup")
        window.setTimeout(() => {
          document.querySelector("#popup-fournisseur-container").classList.toggle("open-popup")
        },1000)
      }
    },
    supp(item){
      if (confirm("Supprimer "+item.value+" de "+item.fournisseur+" de la commande ?")) {
        firebase.database().ref('Commande/'+item.key).remove();

      }
    }
  },
  watch: {
    fournisseur: function() {
      this.produits = this.refs.filter(r => r.fournisseur == this.fournisseur)
    }
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  list-style: none;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar { width: 0 !important }

#app {
  width: 100vw;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 780px){
    justify-content: space-around;
    flex-direction: row;
  }

  .top-container{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 450px;

    .ajout-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  #reference{
    margin-top: 20px;
    margin-bottom: 15px;
  }



  .select-container, .select-fournisseur-container{
    display: flex;
    justify-content: center;
    align-items: center;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: 10px;
      margin-top: 0;
      padding: 0 5px 0 5px;
    }
  }

  .table-container{
    height: 50vh;
    overflow-y: scroll;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 780px){
      height: 95vh
    }

    #toutSupp{
      width: 100%;
      background: red;
      font-weight: 700;
    }
  }

  .referenceAAjouter-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 7vh;

    .popup-container{
      background: rgba(0,0,0,0.5);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      padding-bottom: 5px;
      padding-top: 5px;
      border-radius: 10px;
      opacity: 0;
      transition: opacity 200ms ease;
    }

    .open-popup{
      //ne pas mettre de % pour l'opacité car ça beug lors du build en css
      opacity: 100;
    }

    #referenceAAjouter{
      margin-bottom: 10px;
    }

    .buttons{
      width: 100vw;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media screen and (min-width: 780px){
        width: 350px;
      }

      button{
        width: 30vw;
        @media screen and (min-width: 780px){
          width: 150px;
        }
      }
    }

    .referenceAAjouterInput{
      font-size: 1.5em;
      @media screen and (max-width: 550px){
        width: 80vw;
      }
      padding: 5px;
      border-radius: 10px;
      border: 1px solid #88816e;

      &:focus{
        border: 2px solid grey;
      }
    }
  }

  button{
    font-size: 1.2em;
    padding: 5px;
    border-radius: 10px;
    border: none;
    margin-top: 5px;
    background: #88816e;
    color: white;

    &:active{
      opacity: 0.8;
    }

    @media screen and (max-width: 380px){
      font-size: 1em;
    }
  }

  select{
    padding: 5px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #88816e;
    outline: 0;
    font-size: 1.5em;
    @media screen and (max-width: 380px){
      font-size: 1.2em;
    }

    &:focus{
      border: 2px solid grey;
    }

    .elements{
      font-weight: 700;
    }
  }

  .checked{
    color: #88816e;
  }

  table{
    border: 1px solid;
    font-size: 2em;
    border-radius: 10px;
    @media screen and (max-width: 380px){
      font-size: 1.5em;
    }

    td{
      border: 1px solid;
      padding: 5px;
    }

    td{
      transition: background 200ms ease, color 200ms ease;
      cursor: pointer;
    }

    td:hover{
      background: #88816e;
      color: white;
    }

    th{
      padding: 5px;
      background: #88816e;
      color: white;
    }

    #left{
      border-radius: 10px 0 0 0;
    }
    #right{
      border-radius: 0 10px 0 0;
    }

    .dernierProduit{
      border-radius: 0 0 0 10px;
    }
    .dernierFournisseur{
      border-radius: 0 0 10px 0;
    }
  }
  #suppProduit, #suppFournisseur{
    background: red;
  }
}
</style>
