import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase'

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyDrScm7Eynsat9qtcu3-fswMTVOFpD3GCI",
  authDomain: "commande-au-coin-de-la-baie.firebaseapp.com",
  databaseURL: "https://commande-au-coin-de-la-baie-default-rtdb.firebaseio.com",
  projectId: "commande-au-coin-de-la-baie",
  storageBucket: "commande-au-coin-de-la-baie.appspot.com",
  messagingSenderId: "819525697554",
  appId: "1:819525697554:web:4075d8164af8aca66e58cb",
  measurementId: "G-MFVKENK7XS"
};
firebase.initializeApp(firebaseConfig);


new Vue({
  render: h => h(App),
}).$mount('#app')
